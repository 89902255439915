const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') return `https://streetferret.com`; // prod should use the real domain

  return `http://localhost:3000`; // dev SSR should use localhost
};

/**
 * The URL of the StreetFerret TRPC API (packages/api) accounting for local development or production.
 * @returns The URL of the StreetFerret TRPC API
 */
export const getTrpcApiUrl = () => `${getBaseUrl()}/api/trpc`;
