'use client';
import Script from 'next/script';

export interface AnalyticsProps {
  /**
   * The Umami website ID to track analytics.
   *
   * You can create a new website in the Umami dashboard. https://analytics.streetferret.com/teams/55e4fa26-b975-4aa6-873e-a6d9245cf1fc/settings/websites
   */
  siteId: string;
}
/**
 * Analytics component to track user interactions on the website.
 *
 * Visit https://analytics.streetferret.com to view the analytics dashboard.
 */
export function Analytics({ siteId }: AnalyticsProps) {
  return (
    <Script
      src="https://analytics.streetferret.com/script.js"
      strategy="afterInteractive"
      data-website-id={siteId}
    />
  );
}
