'use client';
import { cn } from '@/lib/utils';
import { Button } from '@streetferret/ui-web/button';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@streetferret/ui-web/select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@streetferret/ui-web/tooltip';
import { Monitor } from 'lucide-react';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';
import { FaMoon, FaSun } from 'react-icons/fa';

/**
 * Toggles the theme between light and dark.
 */
export function ThemeToggle() {
  const { theme, setTheme } = useTheme();

  const mounted = useMounted();
  if (!mounted) {
    return null;
  }

  return (
    <Select value={theme} onValueChange={setTheme}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Color Theme" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="system">
            <div className="flex items-center gap-2">
              <Monitor className="inline-block h-4 w-4" />
              System
            </div>
          </SelectItem>
          <SelectItem value="light">
            <div className="flex items-center gap-2">
              <FaSun className="inline-block" />
              Light
            </div>
          </SelectItem>
          <SelectItem value="dark">
            <div className="flex items-center gap-2">
              <FaMoon className="inline-block" />
              Dark
            </div>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
const themes = [
  {
    name: 'System',
    value: 'system',
    icon: <Monitor className="inline-block h-4 w-4" />,
  },
  {
    name: 'Light',
    value: 'light',
    icon: <FaSun className="inline-block" />,
  },
  {
    name: 'Dark',
    value: 'dark',
    icon: <FaMoon className="inline-block" />,
  },
] as const;
export function ThemeToggleInline() {
  const { theme, setTheme } = useTheme();
  const mounted = useMounted();
  if (!mounted) {
    return null;
  }
  return (
    <div className="flex items-center gap-1 border rounded-full p-1">
      {themes.map(({ name, value, icon }) => (
        <TooltipProvider key={value}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant={theme === value ? 'outline' : 'ghost'}
                onClick={() => setTheme(value)}
                className={cn('!rounded-full !px-2 !h-8')}>
                {icon}
                <span className="sr-only">{name} theme</span>
              </Button>
            </TooltipTrigger>
            <TooltipContent>{name}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  );
}

function useMounted() {
  const [mounted, setMounted] = useState(false);
  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => setMounted(true), []);
  return mounted;
}
