import { cn } from '@streetferret/utils';

export interface DividerProps {
  className?: string;
}
export function Divider({ className }: DividerProps) {
  return (
    <div
      className={cn(
        'border-t border-gray-4 dark:border-dark-4',
        className,
      )}></div>
  );
}
