import React from 'react';

export type UnitType = 'kilometer' | 'mile';

interface PreferencesContextProps {
  unit: UnitType;
}

export const PreferencesContext = React.createContext<PreferencesContextProps>({
  unit: 'mile',
});

interface PreferencesProviderProps extends PreferencesContextProps {
  children: React.ReactNode;
}

export function PreferencesProvider({
  children,
  unit,
}: PreferencesProviderProps) {
  return (
    <PreferencesContext.Provider value={{ unit }}>
      {children}
    </PreferencesContext.Provider>
  );
}

export function usePreferences() {
  const context = React.useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error('usePreferences must be used within a PreferencesProvider');
  }
  return context;
}
export default PreferencesProvider;
